.resumeMaster {
  margin: 0;
  width: 100%;
  height: 90vh;
}
.iframe {
  width: 100%;
  height: 95%;
}

@media screen and (max-width: 415px) {
  .resumeMaster {
   display: flex;
   align-items: center;
   justify-content: center;
  }
 
}