/* * {
  box-sizing: border-box;
}

body {
  margin: 0;
} */

.masterNavbar {
  z-index: 100;
  width: 100%;
  height: 10%;
  /* position: absolute;
  top: 0;
  left: 0;
  right: 0; */
  position: fixed;
  background-color: black;
}

.navbar {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: white;
  font-size: 26px;
}

li:active {
  color: blue;
}

.li {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  float: left;
  text-align: center;
  padding: 14px;
  font-size: 17px;
}

.li :hover {
  color: blue;
  transform: translateY(-5px);
}

li:active {
  color: blue;
}

.sandwith {
  visibility: hidden;
  background-color: blue;
  padding: 14px 16px;
  height: 200px;
  width: 50px;
}

.modal {
  display: none; /* Hidden by default */
}

/* When the screen is less than 600 pixels wide, hide all links, except for the first one ("Home"). Show the link that contains should open and close the topnav (.icon) */
@media screen and (max-width: 415px) {
  .masterNavbar {
    width: 100%;
    height: auto;
  }
  .navbarUL li {
    display: none;
  }
  .sandwith {
    float: right;
    visibility: visible;
    padding: 14px 16px;
    height: 50px;
    width: 50px;
    margin-right: 15px;
  }
  .modalNavbar {
    /* visibility: visible; */
    background-color: blue;
    /* position: absolute;
    top: 10;
    right: 0; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .mli {
    display: flex;
    flex-direction: column;
  }

  .mlink {
    justify-content: space-evenly;
    text-decoration: n;
    font-size: 46px;
    font-weight: bolder;
  }

  .mlink:hover {
    transform: translateX(10px);
  }

  .btn_close {
    width: 100px;
    height: 50px;
    background-color: red;
    border-radius: 15px;
  }

  .modal {
    display: block;
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100vw; /* Full width */
    height: 100dvh; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  }
}
