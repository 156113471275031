.footerMaster {
  background-color: black;
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: fixed;
  bottom: 0;
  /* position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100vw;
  height: 5vh; */
}

.LFooter {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 5px;
}

.MFooter {
  display: flex;
  flex-direction: column;
}

.footerMaster h1 {
  color: white;
}

.icon {
  /* width: 15px;
  height: 15px; */
  animation-name: iconlogo;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  border-radius: 50%;
}

@keyframes iconlogo {
  from {
    box-shadow: 2px 2px 2px lightblue;
  }
  to {
    box-shadow: 6px 6px 6px blue;
  }
}

@media (max-width: 415px) {
  .footerMaster {
    gap: 15px;
    justify-content: space-around;
  }
  .LFooter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }

  .RFooter {
    /* width: 200px; */
  }

  .footerpre {
    font-size: 14px;
    white-space: normal;
  }
  .icon {
    width: 15px;
    height: 15px;
  }

  .MFooter {
    width: fit-content;
    align-items: center;
    justify-content: space-evenly;
  }

  .MFooter h1 {
    font-size: 14px;
  }
}
