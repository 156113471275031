* {
  margin: 0;
  padding: 0;
}

.masterLanding {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.landingContaindrer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  /* height: 50%;
  width: 95%; */
  /* background-color: aqua;
  border-radius: 15px;
  margin-top: 2%;
  margin-bottom: 2%; */
}

.intro {
  text-align: center;
  font-size: medium;
  white-space: normal;
  font-size: 18px;
}

.images {
}

.codelogo {
  animation-name: codelogo;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.Dkimg {
  float: right;
}

.kimg {
  width: 200px;
  height: 200px;
  /* mix-blend-mode: color-burn; */
  border-radius: 50%;
}
/* .Dkimg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background-color: aqua;
} */

@keyframes codelogo {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*  */
svg {
  font-family: "ABeeZee", sans-serif;
  width: 400px;
  height: 100px;
}
svg text {
  animation: stroke 5s infinite alternate;
  stroke-width: 2;
  stroke: #000000;
  font-size: 50px;
}
@keyframes stroke {
  0% {
    fill: rgba(42, 53, 204, 0);
    stroke: rgba(0, 0, 0, 1);
    stroke-dashoffset: 25%;
    stroke-dasharray: 0 50%;
    stroke-width: 2;
  }
  70% {
    fill: rgba(42, 53, 204, 0);
    stroke: rgba(0, 0, 0, 1);
  }
  80% {
    fill: rgba(42, 53, 204, 0);
    stroke: rgba(0, 0, 0, 1);
    stroke-width: 3;
  }
  100% {
    fill: rgba(42, 53, 204, 1);
    stroke: rgba(0, 0, 0, 0);
    stroke-dashoffset: -25%;
    stroke-dasharray: 50% 0;
    stroke-width: 0;
  }
}

.wrapper {
  background-color: #ffffff;
}

@media screen and (max-width: 415px) {
  .masterLanding {
    display: flex;
    width: 100%;
    height:100vh;
  }
  .imagediv {
    display: flex;
    align-items: center;
    width: 95%;
    justify-content: center;
  }
  .codeimg {
    width: 70%;
    height: 70%;
    margin: 25px;
  }

  .landingContaindrer {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-around;
  }

  .hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: center;
    justify-content: center;
    width: 95%;
  }

  .codelogo {
    align-self: center;
    width: 100px;
    height: 100px;
  }

  .intro {
    width: 95%;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 5px;
  }
}
