* {
  margin: 0;
  padding: 0;
}

.contactMaster {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: row;
  overflow-y: hidden; /* Hide vertical scrollbar */
}

.Form {
  padding-top: 5px;
  padding-bottom: 5px;
  width: 50%;
  height: 90vh;
  overflow: scroll;
  scroll-behavior: smooth;
    }

.contactImage {
  width: 50%;
  height: 85vh;
  overflow-y: hidden; /* Hide vertical scrollbar */
}
.contactimg {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 415px) {
  .contactMaster {
    width: 95%;
    height: 90%;
    display: flex;
    flex-direction: row;
    overflow: scroll;
  }
  .contactImage {
    display: none;
  }

  .Form {
    width: 100%;
    height: 100%;
  }
  .gform {
    width: 100%;
    height: 90vh;
  }
}
