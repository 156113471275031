.skillMaster {
  margin: 2vh;
  margin-bottom: 2vh;
  width: 100vw;
  height: 90vh;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.Parallax {
  width: 100%;
  height: 100%;
}

/* General */
.wd_basics,
.wd_fe,
.wd_be,
.wd_service,
.p4Div2,
.p4Div3,
.p5sd1,
.p5sd2,
.p5sd3 {
  align-items: center;
  border-radius: 15px;
  background: #e0e0e0;
  box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
}

.wd_icons:hover {
  transform: scale(1.5, 1.5);
}

/* parallex 1 */
.p1Div1 {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.p1i1 {
  width: 200px;
  height: 200px;
}

.p1pre1 {
  width: 90%;
  font-size: 32px;
  white-space: normal;
  text-align: center;
}
/* parallex 2 */
.p2Div1 {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.p2Div2 {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 10px;
}

.plDiv {
  width: 100px;
  height: 100px;
}

/* parallex 3 */
.p3Div1 {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wd_Container {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  gap: 50px;
}

.wd_basics {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wd_fe,
.wd_be {
  display: flex;
  flex-direction: column;
}

.wd_icons {
  width: 100px;
  height: 100px;
}

/* parallex 4 : Data Base */
.p4Div1 {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.p4DB_Container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.p4Div2,
.p4Div3 {
  display: flex;
  flex-direction: column;
}
/* parallex 5 : others */
.p5Div1 {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.p5Other_Container {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

/* parallex 7 : certifications */
.p7Div1 {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.p7img1 {
  width: 50vw;
  height: 50vh;
}

/* RESPONSIVE DESIGN */
@media screen and (max-width: 415px) {
  .skillMaster {
    width: 100%;
    margin: 0;
  }
}
