.aboutMaster {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.aboutMaster .about_LDiv {
  width: 50%;
  height: 100%;
  /* background-color: aqua; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.anHeader {
  color: blue;
  font-size: 100px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

h2 {
  color: blue;
}

.aboutContent {
  margin: 5px;
  padding: 5px;
  width: 90%;
}

.aboutH {
  align-items: start;
}

.aboutPre {
  text-align: center;
  white-space: normal;
}

.preThanks {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
}

.btnCV {
  background-color: blue;
  padding: 5px;
  width: auto;
  height: 30px;
  color: white;
  font-weight: bold;
}

.about_RDiv {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100vh;
  background-image: url("./images/graph.png");
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
}

.pimg {
  position: absolute;
  bottom: 300px;
  left: 150px;
}

@media screen and (max-width: 415px) {
  .aboutMaster {
    width: 100%;
    height: 85vh;
    display: flex;
    flex-direction: column-reverse;
    scroll-behavior: smooth;
    margin: 0;
    overflow-y: scroll;
  }

  .aboutMaster .about_LDiv {
    width: 95%;
  }

  .anHeader {
    font-size: xxx-large;
  }

  pre {
    white-space: normal;
  }

  .aboutContent {
    margin: 0;
    padding: 0;
    width: 90%;
  }

  .btnCV {
    background-color: blue;
    padding: 5px;
    width: auto;
    height: 30px;
    color: white;
    font-weight: bold;
  }

  .preThanks {
    white-space: normal;
    text-align: center;
  }
  /*  */
  .about_RDiv {
    position: relative;
    width: 100%;
    height: 30%;
    background-image: url("./images/graph.png");
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    background-position: center;
  }
  .pimg {
    position: absolute;
    bottom: 25%;
    left: 19%;
    width: 50%;
    height: 50%;
  }
}
